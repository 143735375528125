import React, { useState } from "react";
import { onRegistration } from "../../../api/auth";
import { ReactComponent as Logo } from "../../../assets/img/realOwlLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import "./Register.css";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";

const Register = () => {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    course_name: "",
    university_name: "",
    class_year: "",
    graduation_year: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate(); //  programmatic navigation
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const universities =
    values.course_name === "Dentistry"
      ? ["University of Manchester"]
      : values.course_name === "Medicine"
      ? ["Imperial College London"]
      : [];

  const class_year = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"];

  const currentYear = new Date().getFullYear();
  const graduation_year = Array.from({ length: 10 }, (_, i) => currentYear + i);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Disable the button
    setIsButtonDisabled(true);

    // Re-enable the button after 3 seconds
    setTimeout(() => setIsButtonDisabled(false), 3000);

    // Check if passwords match before attempting registration

    if (values.password !== values.confirmPassword) {
      setError("🦉 Passwords do not match!");
      toast.error("🦉 Passwords do not match!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    try {
      const { data } = await onRegistration(values);

      setError("");
      setSuccess(data.message);

      toast.success("🦉 Check your email to confirm your account!", {
        position: "top-right",
        autoClose: 5000, // Close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate("/login");
      }, 5000);
      setValues({
        first_name: "",
        last_name: "",
        user_name: "",
        course_name: "",
        university_name: "",
        class_year: "",
        graduation_year: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.log(error.response.data.errors[0].msg);
      setError(error.response.data.errors[0].msg);
      setSuccess("");
      toast.error(`🦉 Error: ${error.response.data.errors[0].msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="register-container">
      <form onSubmit={(e) => onSubmit(e)} className="register-form">
        <div className="register-header">
          <h2 className="brand-title-registration">
            <Link to="/" className="brand-content">
                          <Logo className="register-logo" />
                          <span className="brand-name">
                              Preclinique
                          </span>
            </Link>
          </h2>

          <Typography
            variant="body2"
            color="error" // this applies a red color, indicating importance
            style={{ marginBottom: 20, paddingRight: 70 }}
          >
            Welcome to Preclinique! Please note that our website is currently in
            early access. As such, you may encounter occasional bugs and
            incomplete features. We appreciate your patience and feedback as we
            continue to improve your experience.
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginTop: 10, marginBottom: 20, paddingRight: 70 }}
          >
            Please note: This application is optimized for use on laptops and
            desktops, and may not be fully responsive on mobile or tablet
            devices.
          </Typography>

          <div className="login-prompt">
            Already have an account?{" "}
            <Link to="/login" className="login-text">
              Log in
            </Link>
          </div>
        </div>

        {/* Text Fields and Select Fields */}
        {/* Example TextField */}

        <div className="input-fields">
          <div className="input-group">
            {/* <div>
              <label htmlFor="first_name" className="form-label">
                First Name
              </label>
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                id="first_name"
                name="first_name"
                value={values.first_name}
                placeholder="Gavin"
                required
              />
            </div> */}

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                variant="filled"
                name="first_name"
                value={values.first_name}
                onChange={onChange}
                margin="normal"
              />
            </Grid>

            {/* <div>
              <label htmlFor="last_name" className="form-label">
                Last Name
              </label>
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                value={values.last_name}
                placeholder="Doe"
                required
              />
            </div> */}

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                variant="filled"
                name="last_name"
                value={values.last_name}
                onChange={onChange}
                margin="normal"
              />
            </Grid>
          </div>

          <div className="input-group">
            {/* <div>
              <label htmlFor="user_name" className="form-label">
                User Name
              </label>
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                id="user_name"
                name="user_name"
                value={values.user_name}
                placeholder="Gavin123"
                required
              />
            </div> */}

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="User Name"
                variant="filled"
                name="user_name"
                value={values.user_name}
                onChange={onChange}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="filled">
                <InputLabel id="course-name-label">Course</InputLabel>
                <Select
                  labelId="course-name-label"
                  id="course_name"
                  name="course_name"
                  value={values.course_name}
                  onChange={onChange}
                  label="Course"
                >
                  <MenuItem value="">
                    <em>Select Course</em>
                  </MenuItem>
                  <MenuItem value="Dentistry">Dentistry</MenuItem>
                  <MenuItem value="Medicine">Medicine</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <div>
              <label htmlFor="course_name" className="form-label">
                Course
              </label>
              <select
                name="course_name"
                id="course_name"
                className="form-control"
                value={values.course_name}
                onChange={onChange}
                required
              >
                <option value="">Select Course</option>
                <option value="Dentistry">Dentistry</option>
                <option value="Medicine">Medicine</option>
              </select>
            </div> */}
          </div>

          <div className="input-group">
            {/* <div>
              <label htmlFor="university_name" className="form-label">
                University Name
              </label>
              <select
                name="university_name"
                id="university_name"
                className="form-control"
                value={values.university_name}
                onChange={onChange}
                required
              >
                <option value="">Select University</option>
                {universities.map((uni) => (
                  <option key={uni} value={uni}>
                    {uni}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="class_year" className="form-label">
                Class Year
              </label>
              <select
                name="class_year"
                id="class_year"
                className="form-control"
                value={values.class_year}
                onChange={onChange}
                required
              >
                <option value="">Select Class Year</option>
                {class_year.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div> */}

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="filled">
                <InputLabel id="university-name-label">
                  University Name
                </InputLabel>
                <Select
                  labelId="university-name-label"
                  id="university_name"
                  name="university_name"
                  value={values.university_name}
                  onChange={onChange}
                  label="University Name"
                >
                  <MenuItem value="">
                    <em>Select University</em>
                  </MenuItem>
                  {universities.map((uni) => (
                    <MenuItem key={uni} value={uni}>
                      {uni}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="filled">
                <InputLabel id="class-year-label">Class Year</InputLabel>
                <Select
                  labelId="class-year-label"
                  id="class_year"
                  name="class_year"
                  value={values.class_year}
                  onChange={onChange}
                  label="Class Year"
                >
                  <MenuItem value="">
                    <em>Select Class Year</em>
                  </MenuItem>
                  {class_year.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </div>

          <div className="input-group">
            {/* <div>
              <label htmlFor="graduation_year" className="form-label">
                Graduation Year
              </label>
              <select
                name="graduation_year"
                id="graduation_year"
                className="form-control"
                value={values.graduation_year}
                onChange={onChange}
                required
              >
                <option value="">Select Graduation Year</option>
                {graduation_year.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={values.email}
                placeholder="test@gmail.com"
                required
              />
            </div> */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="filled">
                <InputLabel id="graduation-year-label">
                  Graduation Year
                </InputLabel>
                <Select
                  labelId="graduation-year-label"
                  id="graduation_year"
                  name="graduation_year"
                  value={values.graduation_year}
                  onChange={onChange}
                  label="Graduation Year"
                >
                  <MenuItem value="">
                    <em>Select Graduation Year</em>
                  </MenuItem>
                  {graduation_year.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email Address"
                variant="filled"
                id="email"
                name="email"
                type="email"
                value={values.email}
                onChange={onChange}
                required
                placeholder="test@gmail.com"
                margin="normal"
              />
            </Grid>
          </div>

          <div className="input-group">
            {/* <div>
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                onChange={(e) => onChange(e)}
                type="password"
                value={values.password}
                className="form-control"
                id="password"
                name="password"
                placeholder="Enter Password"
                required
              />
            </div>
            <div id="confirm-password-half-width">
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </label>
              <input
                onChange={onChange}
                type="password"
                value={values.confirmPassword}
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Re-enter password"
                required
              />
            </div> */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                variant="filled"
                id="password"
                name="password"
                value={values.password}
                onChange={onChange}
                required
                placeholder="Enter Password"
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                variant="filled"
                id="confirmPassword"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={onChange}
                required
                placeholder="Re-enter password"
                margin="normal"
              />
            </Grid>
          </div>

          <div className="form-footer">
            <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
            <div style={{ color: "green", margin: "10px 0" }}>{success}</div>
            <button type="submit" disabled={isButtonDisabled}>
              Register
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
