import React, { useState } from "react";
import "./WhoAreWeSection.css";
import { ReactComponent as WhoAreWeOwlQuestion } from "../../assets/img/WhoAreWeOwlQuestion.svg";
import RoshaanImage from "../../assets/img/Roshaan.png";
import SyedImage from "../../assets/img/Syed.png";
import EbadurImage from "../../assets/img/Ebadur.png";
import HaseebImage2 from "../../assets/img/Haseeb2.png";
import UmairImage from "../../assets/img/Umair.png";
import OwlLogoSvg from "../../assets/img/realOwlLogo.svg";
import Switch from "@mui/material/Switch";
import AmmarImage from "../../assets/img/Ammar.png"; // Example image
import HusnaaImage from "../../assets/img/Husnaa.png"; // Example image

const WhoAreWeSection = () => {
    const [showTeam, setShowTeam] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [fade, setFade] = useState(true);

    const toggleShowTeam = () => {
        setShowTeam(!showTeam);
    };

    const pages = [
        {
            name: "Question Writers",
            teamMembers: [
                {
                    name: "Syed Maududi",
                    image: SyedImage,
                    degree: "5th Year MBBS @ ICL",
                    role: "Question Writer",
                },
                {
                    name: "Roshaan Choudhary",
                    image: RoshaanImage,
                    degree: "4th Year MBBS @ ICL",
                    role: "Question Writer",
                },
                {
                    name: "Haseeb Tahir",
                    image: HaseebImage2,
                    degree: "4th Year BDS @ UoM",
                    role: "Question Writer",
                },
                {
                    name: "Umair Shams",
                    image: UmairImage,
                    degree: "4th Year MBBS @ ICL",
                    role: "Question Writer",
                },
                {
                    name: "Qasim Ahmed",
                    image: OwlLogoSvg,
                    degree: "5th Year MBBS @ ICL",
                    role: "Question Writer",
                },
            ],
        },
        {
            name: "Brand Ambassadors",
            teamMembers: [
                {
                    name: "Aisha Kareem",
                    image: OwlLogoSvg,
                    degree: "4th Year BDS @ UoM",
                    role: "Brand Ambassador",
                },
                {
                    name: "Husnaa Ahmed",
                    image: HusnaaImage,
                    degree: "4th Year BDS @ TUoS",
                    role: "Brand Ambassador",
                },
                {
                    name: "Ammar Motala",
                    image: AmmarImage,
                    degree: "3rd Year MBBS @ ICL",
                    role: "Brand Ambassador",
                },
                {
                    name: "June Wong",
                    image: OwlLogoSvg,
                    degree: "5th Year MBBS @ UoN",
                    role: "Brand Ambassador",
                },
                {
                    name: "Syeda Kazmi",
                    image: OwlLogoSvg,
                    degree: "4th Year BDS @ UoM",
                    role: "Brand Ambassador",
                },
            ],
        },
        {
            name: "Technical Team",
            teamMembers: [
                {
                    name: "Ebadur Rahman",
                    image: EbadurImage,
                    degree: "MEng Engineering @ UoM",
                    role: "Software Developer",
                },
                {
                    name: "Furqan Khan",
                    image: OwlLogoSvg,
                    degree: "BSc Computer Science @ MMU",
                    role: "Software Developer",
                },
                {
                    name: "Izzah Choudhary",
                    image: OwlLogoSvg,
                    degree: "A-Levels",
                    role: "Digital Marketer",
                },
            ],
        },
    ];

    const changePage = (newIndex) => {
        setFade(false);
        setTimeout(() => {
            setCurrentPage(newIndex);
            setFade(true);
        }, 500); // This timeout should match your CSS fade effect duration
    };

    const nextPage = () => {
        const newIndex = (currentPage + 1) % pages.length;
        changePage(newIndex);
    };

    const previousPage = () => {
        const newIndex = currentPage === 0 ? pages.length - 1 : currentPage - 1;
        changePage(newIndex);
    };

    return (
        <section className="who-are-we-section">
            <h2>
                Who Are <span className="blue-text">We</span>?
            </h2>

            <Switch checked={showTeam} onChange={toggleShowTeam} />

            {showTeam ? (
                <div className={`whoweare-content ${fade ? "fade-in" : "fade-out"}`}>
                    <h3>{pages[currentPage].name}</h3>
                    <div className="whoweare-navigation">
                        <button
                            onClick={previousPage}
                            className="whoweare-navigation-button"
                        >
                            &larr;
                        </button>
                        <button onClick={nextPage} className="whoweare-navigation-button">
                            &rarr;
                        </button>
                    </div>
                    <div className="team-images">
                        {pages[currentPage].teamMembers.map((member) => (
                            <div key={member.name} className="team-member">
                                <div
                                    className={`team-blob ${member.image.endsWith(".svg") ? "team-blob-svg" : ""
                                        }`}
                                    style={{ backgroundImage: `url(${member.image})` }}
                                ></div>

                                <span className="team-name">{member.name}</span>
                                <span className="team-degree">{member.degree}</span>
                                <span className="team-role">{member.role}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div>
                    <div className="who-are-we-content">
                        <div className="blob">
                            <div className="blob-text">
                                We are a vibrant community comprising medics, dentists, and
                                engineers, each bringing a unique blend of passion and expertise
                                to the table.
                            </div>
                        </div>
                        <WhoAreWeOwlQuestion className="WhoAreWeOwlQuestion" />
                        <div className="blob blob-second">
                            <div className="blob-text">
                                With each of us hailing from unique backgrounds, we've merged
                                our expertise to cover all angles and fulfill the needs of the
                                Preclinique community.
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default WhoAreWeSection;