import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useFetchQuestionBySubcatIds,
    useFetchQuestionsByIds,
} from "../../../../../../hooks/FetchQuestion";

import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import "../DashboardQuestionInterfaceStyles/DashboardQuestions.css";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { getQuestionImageLink } from "../../../../../../api/auth";
export default function DashboardQuestions({
    onChecked,
    isSubmitted,
    isCorrect,
    getCorrectOptionId,
}) {
    const dispatch = useDispatch();
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [revealButtonVisible, setRevealButtonVisible] = useState(true);
    const [textboxValue, setTextboxValue] = useState(""); // New state for textbox value
    const userId = useSelector((state) => state.auth.userId);
    const [image, setImage] = useState(null);

    const selectedSubcategories = useSelector(
        (state) => state.selectedSubcategories
    );
    const selectedQuestionIds = useSelector(
        (state) => state.selectedQuestions.questions
    );

    const [checkedAnswers, setCheckedAnswers] = useState([]);
    const { trace } = useSelector((state) => state.questions);
    const result = useSelector((state) => state.result.result);
    const [{ isLoading, apiData, serverError }] =
        useFetchQuestionsByIds(selectedQuestionIds);

    const questionObj = useSelector(
        (state) => state.questions.queue[state.questions.trace]
    );
   

    useEffect(() => {
        const fetchImage = async () => {
            if (questionObj && questionObj.id) { // Ensure questionObj and id are defined
                const imageLink = await getQuestionImageLink(questionObj.id); // Await the image link
                setImage(imageLink.data.session[0].image); // Set the image after fetching
            }
        };

        fetchImage();
    }, [questionObj]); // Trigger only when questionObj changes // Trigger only when questionObj changes // Dependencies, in this case, questionObj.id

    //console.log("LOOK AT ME", image);
    //const image = 'https://preclinique-my.sharepoint.com/personal/rchoudhary_preclinique_co_uk/Documents/Question%20Bank%20Images/1.5%20Cholesterol.jpg';


    function onSelect(optionId, index) {
        onChecked(questionObj.id, optionId);
        let newCheckedAnswers = [...checkedAnswers];
        newCheckedAnswers[trace] = index;
        setCheckedAnswers(newCheckedAnswers);
    }

    const handleRevealButtonClick = () => {
        setShowCheckboxes(true);
        setShowAnswer(true);
        setRevealButtonVisible(false);
    };

    const handleOptionChange = (e) => {
        const selectedValue = e.target.value;
        let selectedOptionId;
        if (selectedValue === "correct") {
            const correctOption = questionObj.options.find(
                (option) => option.is_correct
            );
            selectedOptionId = correctOption ? correctOption.option_id : null;
        } else if (selectedValue === "incorrect") {
            selectedOptionId = "1";
        }
        setSelectedOption(selectedValue); // Update selectedOption state
        onChecked(questionObj.id, selectedOptionId);
    };

    useEffect(() => {
        setRevealButtonVisible(true);
        setShowCheckboxes(false);
        setShowAnswer(false);
        setTextboxValue(""); // Reset textbox value to its placeholder
        setSelectedOption(""); // Reset selectedOption to empty string to uncheck radio buttons
    }, [trace]); // Triggered when `trace` changes

    function renderControl(i, option) {
        if (isSubmitted[trace]) {
            if (checkedAnswers[trace] === i) {
                return isCorrect[trace] ? (
                    <div
                        style={{
                            padding: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CheckCircleOutlineIcon color="primary" />
                    </div>
                ) : (
                    <div
                        style={{
                            padding: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <HighlightOffIcon color="error" />
                    </div>
                );
            }
            if (getCorrectOptionId(questionObj.id) === option.option_id) {
                return (
                    <div
                        style={{
                            padding: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CheckCircleOutlineIcon color="primary" />
                    </div>
                );
            }
        }
        return (
            <>
                <Radio
                    checked={checkedAnswers[trace] === i}
                    onChange={() => onSelect(option.option_id, i)}
                    name="options"
                    color="primary"
                    disabled={isSubmitted[trace]}
                />
            </>
        );
    }

    function getOptionClass(i, option) {
        if (isSubmitted[trace]) {
            if (checkedAnswers[trace] === i) {
                return isCorrect[trace] ? "correct-answer" : "incorrect-answer";
            }
            if (getCorrectOptionId(questionObj.id) === option.option_id) {
                return "correct-answer";
            }
        }
        return "";
    }

    if (isLoading) return <h3 className="text-light">Loading...</h3>;
    if (serverError)
        return <h3 className="text-light">{serverError || "Unknown Error"}</h3>;

    if (!questionObj) {
        return <div>Loading...</div>;
    }

    return (
        <div className="questions">
            {questionObj.type === 1 && (
                <>
                    <h2 className="question-text">{questionObj?.question}</h2>
                    <div>
                        {image ? (
                            <img src={image} alt="OneDrive Image" style={{ maxWidth: "100%", height: "auto" }} />
                        ) : null}
                    </div>
                    <FormGroup role="radiogroup">
                        {questionObj?.options.map((option, i) => (
                            <div
                                key={option.option_id}
                                className={`option-border-style ${getOptionClass(i, option)}`}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                    }}
                                >
                                    <FormControlLabel
                                        value={option.option_id}
                                        control={renderControl(i, option)}
                                        label={option.option_text}
                                        style={{
                                            width: "100%",
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            paddingRight: "72px",
                                            boxSizing: "border-box",
                                            paddingTop: "2px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                                <Collapse in={isSubmitted[trace]} timeout="auto" unmountOnExit>
                                    <div className="explanation-style">
                                        {option?.explanation_text}
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                    </FormGroup>
                </>
            )}

            {questionObj.type === 2 && (
                <div>
                    <h2 className="question-text">{questionObj?.question}</h2>
                    <div>
                        {image ? (
                            <img src={image} alt="OneDrive Image" style={{ maxWidth: "100%", height: "auto" }} />
                        ) : null}
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Enter your answer"
                            className="fancy-text-input"
                            value={textboxValue}
                            onChange={(e) => setTextboxValue(e.target.value)}
                        />
                        {revealButtonVisible && (
                            <button className="reveal-button" onClick={handleRevealButtonClick}>
                                Reveal Answer
                            </button>
                        )}
                        {showCheckboxes && (
                            <div className="checkbox-container">
                                <div>
                                    <input
                                        type="radio"
                                        id="correct"
                                        name="answer"
                                        value="correct"
                                        checked={selectedOption === "correct"}
                                        onChange={handleOptionChange}
                                        className="checkbox-input"
                                    />
                                    <label htmlFor="correct" className="checkbox-label">Correct</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="incorrect"
                                        name="answer"
                                        value="incorrect"
                                        checked={selectedOption === "incorrect"}
                                        onChange={handleOptionChange}
                                        className="checkbox-input"
                                    />
                                    <label htmlFor="incorrect" className="checkbox-label">Incorrect</label>
                                </div>
                            </div>
                        )}
                    </div>
                    {showAnswer && (
                        <div>
                            Correct Answer:
                            {questionObj.options.map(
                                (option) =>
                                    option.is_correct && (
                                        <div key={option.option_id}>{option.option_text}</div>
                                    )
                            )}
                        </div>
                    )}
                </div>
            )}
            {isSubmitted[trace] && questionObj.lectureReference && (
                <div className="lecture-reference">
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontStyle: "italic" }}
                    >
                        Refer to Lecture: {questionObj.lectureReference}
                    </Typography>
                </div>
            )}
        </div>
    );
}
