// QuestionInterface.js
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import DashboardQuestions from "./DashboardQuestionInterfaceComponents/DashboardQuestions";
import DashboardQuestionsById from "./DashboardQuestionInterfaceComponents/DashboardQuestionsById";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField"; // Import TextField


import {
  MoveNextQuestion,
  MovePrevQuestion,
} from "../../../../../hooks/FetchQuestion";
import { PushAnswer } from "../../../../../hooks/setResult";

import { setTraceAction } from "../../../../../redux/reducers/question_reducer";

import "./DashboardQuestionInterfaceStyles/DashboardQuestionInterface.css";

import IconButton from "@mui/material/IconButton";
import FlagIcon from "@mui/icons-material/Flag";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

/** redux store import */
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardReviewCard from "./DashboardQuestionInterfaceComponents/DashboardReviewCard";
import {
  completeQuestion,
  flagQuestion,
  insertForReview,
  unflagQuestion,
    saveSession,
    reportQuestionInsert,
} from "../../../../../api/auth";

const DashboardQuestionInterface = () => {
  const [flags, setFlags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector((state) => state.auth.userId);
  const [isFlagged, setIsFlagged] = useState(false); // State to track if flagged
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState([]);
  const [isCorrect, setIsCorrect] = useState([]);
    const [reviewCardOpen, setReviewCardOpen] = useState(false);
    //const [openDialog, setOpenDialog] = useState(false); // Existing dialog for finish button
    const [openReportDialog, setOpenReportDialog] = useState(false); // Ne
    const [reportText, setReportText] = useState(""); // State to store the report text
    const date = new Date();
    const university = useSelector((state) => state.auth.universityName);
    const course = useSelector((state) => state.auth.courseName);

  const questionType = useSelector((state) => state.questions.type); // question type for vsaq
  const handleQuestionClick = (questionNumber) => {
    dispatch(setTraceAction(questionNumber - 1)); // dispatch action to update the trace value
  };
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenReportDialog = () => setOpenReportDialog(true);
    const handleCloseReportDialog = () => setOpenReportDialog(false);
    const handleReportTextChange = (event) => setReportText(event.target.value); // Update text state


  const flaggedQuestions = flags.reduce((acc, flagged, index) => {
    if (flagged) acc.push(index + 1);
    return acc;
  }, []);

  const handleFinishClick = () => {
    console.log("Finish clicked, flagged questions:", flaggedQuestions);
    if (flaggedQuestions.length > 0) {
      setOpenDialog(true); // Open dialog if there are flagged questions
    } else {
      handleResults(); // Otherwise proceed to results
    }
    };

    const handleSubmitReport = () => {

        // Get the current question's ID based on the trace value
        const questionId = queue[trace]?.id; // Get the ID of the current question

        if (!questionId) {
            console.error("Question ID not found");
            return;
        }

        reportQuestionInsert(questionId,
            reportText,//problem,
            university,
            course,
           // category,
           // subcategory,
            date);
        console.log("Report submitted:", reportText);
        // Placeholder for actual report submission logic
        setReportText(""); // Clear text after submission
        handleCloseReportDialog();
    };

  const handleCloseDialog = (shouldFinish) => {
    setOpenDialog(false);
    if (shouldFinish) {
      setIsLoading(true);
      setTimeout(() => {
        handleResults();
        setIsLoading(false);
      }, 2000);
    }
  };
  const didIComeFromResume = useSelector(
    (state) => state.questions.gettingResultFromResume
  );

  const answers = useSelector((state) => state.questions.answers);
  const questionIds = useSelector((state) => state.questions.queue); //question queue for prog saving
  const sessionarray = questionIds.map((question) => question.id);

  const questionnumber = useSelector((state) => state.questions.trace); //saves pos in queue for saving
  const result = useSelector((state) => state.result.result);
  const { queue, trace } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
    const navigate = useNavigate();
    //const questionID = useSelector((state) => state.questions.queue[questionnumber].id);

  useEffect(() => {
    if (queue && queue.length > 0) {
      setFlags(Array(queue.length).fill(false));
    }
  }, [queue]); // This ensures flags are re-initialized whenever the queue changes.

  useEffect(() => {
    setIsFlagged(false); // Reset the flag color whenever `trace` changes
  }, [trace]);

  async function handleFlagClick() {
    let newFlags = [...flags];
    const index = trace; // assuming `trace` is the index of the current question

    try {
      if (newFlags[index]) {
        // Attempt to unflag the question
        const response = await unflagQuestion(userId, queue[index].id);
        if (response.data.success) {
          console.log("Question unflagged successfully", questionType);
          newFlags[index] = false;
        } else {
          console.error("Error unflagging question:", response.data.message);
        }
      } else {
        // Attempt to flag the question
        const response = await flagQuestion(userId, queue[index].id);
        if (response.data.success) {
          console.log("Question flagged successfully", questionType);
          newFlags[index] = true;
        } else {
          console.error("Error flagging question:", response.data.message);
        }
      }
      setFlags(newFlags);
    } catch (error) {
      console.error("Error in flag/unflag operation:", error);
      alert(
        "There was an error processing your flagging request. Please try again later."
      );
    }
  }

  /** next button event handler */
  function onNext() {
    if (trace < queue.length) {
      /** update the trace value by one using MoveNextAction*/
      dispatch(MoveNextQuestion());
    }
  }

  function onPrev() {
    if (trace > 0) {
      dispatch(MovePrevQuestion());
    }
  }

  function onChecked(questionId, optionId) {
    let updatedAnswers = [...checkedAnswers];
    updatedAnswers[trace] = { question_id: questionId, option_id: optionId };
    setCheckedAnswers(updatedAnswers);
  }

  const handleResults = () => {
    if (queue.length) {
      navigate("/dashboard/question-bank/questions/results", {
        replace: true,
      });
    } else {
      alert("Please wait for the questions to load before proceeding.");
    }
  };

  function isAnswerCorrect(userAnswer) {
    // Fetch the corresponding correct answer object from the answers array
    const correctAnswer = answers.find(
      (a) => a.question_id === userAnswer.question_id
    );

    if (!correctAnswer) {
      console.error(
        `Correct answer for question ID ${userAnswer.question_id} not found in answers.`
      );
      return false;
    }

    // Return true if the user's selected option (option_id from result) matches the correct_option_id from the answers array
    return userAnswer.option_id === correctAnswer.correct_option_id;
  }

  const handleSaveSession = async () => {
    try {
      const saveSessionResult = await saveSession({
        user_id: userId,
        sessionarray: sessionarray, // Use questionIds instead of sessionarray
        questionnumber: questionnumber,
      });

      if (saveSessionResult.data && saveSessionResult.data.success) {
        console.log("Session saved successfully");
      } else {
        console.log("Error saving session:", saveSessionResult.data.message);
      }
    } catch (error) {
      console.error("Error saving session:", error.message);
    }
  };

  async function handleSubmit() {
    const currentAnswer = checkedAnswers[trace];

    if (!currentAnswer) {
      console.error("Current answer not found in checkedAnswers.");
      return;
    }

    dispatch(PushAnswer(currentAnswer));

    const correct = isAnswerCorrect(currentAnswer);

    const correctAnswerForLogging = answers.find(
      (a) => a.question_id === currentAnswer.question_id
    );

    let newIsSubmitted = [...isSubmitted];
    newIsSubmitted[trace] = true;
    setIsSubmitted(newIsSubmitted);

    let newIsCorrect = [...isCorrect];
    newIsCorrect[trace] = correct;
    setIsCorrect(newIsCorrect);

    // Call the API to mark the question as completed for the user

    // const response = await completeQuestion(userId, queue[trace].id, true);
    // if (response.data.success) {
    //   console.log("Question marked as completed successfully");
    // } else {
    //   console.error(
    //     "Error marking the question as completed:",
    //     response.data.error
    //   );
    // }
    try {
      const question = queue.find((q) => q.id === currentAnswer.question_id);
      const userOption = question.options.find(
        (opt) => opt.option_id === currentAnswer.option_id
      );
      const correctOption = question.options.find((opt) => opt.is_correct);

      await completeQuestion(userId, question.id, true, correct);

      await insertForReview(
        userId,
        question.id,
        question.question,
        userOption?.option_text, // User selected option text
        correctOption?.option_text, // Correct option text
        correct
      );
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  }

  const handleSubmitAndSaveSession = () => {
    handleSubmit(); // Call handleSubmit function
    handleSaveSession(); // Call handleSaveSession function
  };
  // Function to get the explanation for a given optionId from a question
  function getExplanation(questionId, optionId) {
    const question = queue.find((q) => q.id === questionId);
    return question?.options.find((opt) => opt.option_id === optionId)
      ?.explanation_text;
  }

  // Function to get the correct optionId for a given question
  function getCorrectOptionId(questionId) {
    const question = queue.find((q) => q.id === questionId);
    return question?.options.find((opt) => opt.is_correct)?.option_id;
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p: 2,
      }}
    >
      <Container
        height="100%"
        maxWidth="1600px"
        sx={{ mt: 4, mb: 4, height: "calc(100vh - 8rem)" }}
      >
        <Paper
          // Adjust the number to increase or decrease shadow depth
          square={false} // If set to true, it removes the border-radiu
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "80vh", // Setting minimum height
            height: "100%",
            position: "relative",
            overflow: "auto",
          }}
        >
          <div className="nav-button-container">
            <div className="inner-container">
              <div className="question-counter">
                Question {trace + 1} of {queue.length}
                {/* Flag IconButton added here */}
                <IconButton
                  className="flag-button"
                  onClick={handleFlagClick}
                  style={{ color: flags[trace] ? "red" : "inherit" }} // change color if flagged for current question
                >
                  <FlagIcon />
                </IconButton>
              </div>

              <Box className="mui-nav-box-root">
                {trace > 0 && (
                  <IconButton onClick={onPrev} aria-label="previous">
                    <ArrowBackIosIcon className="back-icon" />
                  </IconButton>
                )}
                {trace < queue.length - 1 ? (
                  <IconButton onClick={onNext} aria-label="next">
                    <ArrowForwardIosIcon />
                  </IconButton>
                ) : (
                  <button className="btn results" onClick={handleResults}>
                    Results & Review
                                      </button>

                )}
              </Box>
            </div>
          </div>
          {/* Add  question content
              
          here - will add some sort of standalone component for the question ui */}

          <div className="parent-content-container">
            <div className="content-container">
              <DashboardQuestions
                onChecked={(qId, optId) => onChecked(qId, optId)}
                isSubmitted={isSubmitted}
                isCorrect={isCorrect}
                getCorrectOptionId={getCorrectOptionId}
              />
            </div>

            <div
              className="review-card-container"
              style={{ width: reviewCardOpen ? "300px" : "50px" }}
            >
              <DashboardReviewCard
                trace={trace}
                isSubmitted={isSubmitted}
                isCorrect={isCorrect}
                flags={flags} // Make sure to pass this prop
                onToggle={(isOpen) => setReviewCardOpen(isOpen)}
                onQuestionClick={handleQuestionClick}
              />
            </div>
          </div>

          <div className="submit-button-container">
            <div className="inner-container">
                          <Box className="mui-box-root">
                              {/* New Report Button */}
                              <button
                                  className="btn report"
                                  onClick={handleOpenReportDialog}
                                  style={{ backgroundColor: "red", color: "white" }}
                              >
                                  Report
                              </button>
                <button
                  className="btn submit"
                  onClick={handleSubmitAndSaveSession}
                  disabled={isSubmitted[trace]}
                >
                  Submit
                              </button>


                <button className="btn results" onClick={handleFinishClick}>
                  {isLoading ? <CircularProgress size={24} /> : "Finish"}
                              </button>

                              <Dialog open={openReportDialog} onClose={handleCloseReportDialog}>
                                  <DialogTitle>Report an Issue</DialogTitle>
                                  <DialogContent>
                                      <DialogContentText>
                                          Please describe the issue with this question.
                                      </DialogContentText>
                                      <TextField
                                          autoFocus
                                          margin="dense"
                                          id="report-issue"
                                          label="Issue Description"
                                          type="text"
                                          fullWidth
                                          variant="outlined"
                                          value={reportText}
                                          onChange={handleReportTextChange} // Capture user input
                                      />
                                  </DialogContent>
                                  <DialogActions>
                                      <Button onClick={handleCloseReportDialog} color="primary">
                                          Cancel
                                      </Button>
                                      <Button onClick={handleSubmitReport} color="primary">
                                          Submit
                                      </Button>
                                  </DialogActions>
                              </Dialog>

                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogTitle>{"Finish Session?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      You have flagged questions: {flaggedQuestions.join(", ")}.
                      Do you want to review them or finish the session?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseDialog(false)}>
                      Review
                    </Button>
                    <Button onClick={() => handleCloseDialog(true)} autoFocus>
                      Finish
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </div>
          </div>
        </Paper>
      </Container>
    </Box>
  );
};

export default DashboardQuestionInterface;
